import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { StoreState } from "reducers";

export interface ChangeReturnStepAction {
  type: ActionTypes.changeReturnStep;
  payload: ReturnSteps;
}

export enum ReturnSteps {
  customerInfo,
  stageOneQuestions,
  returnItems,
  shippingMethod,
  shippingAddress,
  receivablePaymentDetails,
  summaryAndPaymentDetails,
  submitted,
  success
}

export const changeReturnStep = (step: ReturnSteps): ChangeReturnStepAction => {
  return {
    type: ActionTypes.changeReturnStep,
    payload: step
  };
};

export const returnStepBack = (): ((dispatch: Dispatch, getState: () => StoreState) => void) => {
  return (dispatch: Dispatch, getState: () => StoreState): void => {
    const { returnStep } = getState();
    if (returnStep === ReturnSteps.customerInfo) return;

    dispatch(changeReturnStep(returnStep - 1));
  };
};
