import React from "react";
import { useInstantExchangeTransactionInfo } from "hooks/useInstantExchange";
import { InstantExchangeTransactionInfoRow } from "constants/instantExchange";
import { useInstantExchangeData } from "hooks/useInstantExchange";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { getFormattedPriceString } from "helpers/currency";
import { Grid, Divider, Label, Popup, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Loading } from "components";
import { getInstantExchangeChargeReason } from "./helpers";

export const PostSubmissionInstantExchangeSection: React.FC = (): JSX.Element => {
  const { instantExchangeTransactionInfo, isLoading } = useInstantExchangeTransactionInfo();
  const {
    temporaryHold,
    outstandingPayment,
    totalAmountToPay,
    totalOutstandingAmount,
    chargeAdjustment
  } = instantExchangeTransactionInfo;
  const {
    instantExchangeData: { paymentOption }
  } = useInstantExchangeData();
  const {
    instantExchangeInvoice: { total }
  } = useInvoiceContext();
  const { t } = useTranslation("orderFlow");

  const chargeAmountCents = total.cents > 0 ? total.cents : 0;
  const chargeAmount = getFormattedPriceString(chargeAmountCents, total.currencyIso);
  const chargeReason = getInstantExchangeChargeReason(chargeAmountCents, paymentOption, t);
  const totalDueColor = chargeAmountCents > 0 ? "#C23329" : "black";

  const InstantExchangeSectionRow: React.FC<{ row: InstantExchangeTransactionInfoRow }> = ({ row }): JSX.Element => {
    const { title, status, descriptionLines, amount } = row;
    const getBadgeStyle = (status: string) => {
      if (["Charged", "Charge Failed"].includes(status)) {
        return { backgroundColor: "#F5D4C9", color: "#C23329" };
      } else {
        return { backgroundColor: "#DDF6C6", color: "#438225" };
      }
    };

    return (
      <Grid.Row style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
        <Grid.Column computer={12}>
          {title} {status && <Label style={getBadgeStyle(status)}>{status}</Label>}
          {descriptionLines &&
            descriptionLines.map((descriptionLine, index) => (
              <div
                style={{ fontStyle: "italic", fontSize: "0.85rem", fontWeight: "normal", paddingTop: "0.5rem" }}
                key={index}
              >
                {descriptionLine}
              </div>
            ))}
        </Grid.Column>
        <Grid.Column computer={4} textAlign="right">
          {amount}
        </Grid.Column>
      </Grid.Row>
    );
  };

  const ChargeAdjustmentRow: React.FC<{ row: InstantExchangeTransactionInfoRow }> = ({ row }): JSX.Element => {
    const { title, descriptionLines, amount } = row;

    return (
      <Grid.Row style={{ paddingTop: "0.5rem", paddingBottom: "1rem", fontWeight: "400" }}>
        <Grid.Column computer={12}>
          {title}
          <Popup
            trigger={<Icon name="info circle" size="small" />}
            content={descriptionLines}
            position="top right"
            wide="very"
          />
        </Grid.Column>
        <Grid.Column computer={4} textAlign="right" style={{ fontWeight: "400" }}>
          {amount}
        </Grid.Column>
      </Grid.Row>
    );
  };

  if (isLoading) return <Loading />;

  return (
    <div style={{ padding: "8px 16px" }}>
      <div style={{ fontWeight: "bold", color: "gray" }}>
        <Grid columns={2}>
          {temporaryHold && <InstantExchangeSectionRow row={temporaryHold} />}
          {outstandingPayment && <InstantExchangeSectionRow row={outstandingPayment} />}
          {totalAmountToPay && <InstantExchangeSectionRow row={totalAmountToPay} />}
          {totalOutstandingAmount && <InstantExchangeSectionRow row={totalOutstandingAmount} />}
          {chargeAdjustment && <ChargeAdjustmentRow row={chargeAdjustment} />}
        </Grid>
      </div>

      {chargeAmountCents > 0 && <Divider />}
      <div style={{ fontWeight: "bold", color: "gray" }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column computer={12} style={{ color: totalDueColor }}>
              {t("summary.instantExchange.totalDueToday")}
              {chargeReason && (
                <div style={{ color: "gray", fontWeight: "normal", fontStyle: "italic", fontSize: "0.85rem" }}>
                  {chargeReason}
                </div>
              )}
            </Grid.Column>
            <Grid.Column computer={4} textAlign="right" style={{ color: totalDueColor }}>
              {chargeAmount}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};
