import { ActionTypes } from "./types";

export interface BackgroundImageUrls {
  webp: {
    small: URL | null;
    medium: URL | null;
    large: URL | null;
  };
  jpg: {
    small: URL | null;
    medium: URL | null;
    large: URL | null;
  };
}
export interface LogoImageUrls {
  webp: {
    navbar: URL | null;
  };
  png: {
    navbar: URL | null;
  };
}

export interface FrontendConfigs {
  primaryColor: string | null;
  title: string;
  backgroundImageUrls: BackgroundImageUrls;
  mobileBackgroundImageUrls: BackgroundImageUrls;
  logoImageUrls: LogoImageUrls;
  faviconUrl: URL;
  loading: boolean;
}

export interface ClearConfigLoadingAction {
  type: ActionTypes.clearConfigLoading;
}

export interface SetBackgroundImageUrlAction {
  type: ActionTypes.setBackgroundImageUrl;
  payload: BackgroundImageUrls;
}

export interface SetMobileBackgroundImageUrlAction {
  type: ActionTypes.setMobileBackgroundImageUrl;
  payload: BackgroundImageUrls;
}

export interface SetPrimaryColorAction {
  type: ActionTypes.setPrimaryColor;
  payload: FrontendConfigs["primaryColor"];
}

export interface SetLogoImageUrlAction {
  type: ActionTypes.setLogoImageUrls;
  payload: FrontendConfigs["logoImageUrls"];
}

export interface SetFaviconUrlAction {
  type: ActionTypes.setFaviconUrl;
  payload: FrontendConfigs["faviconUrl"];
}

export interface SetTitleAction {
  type: ActionTypes.setTitle;
  payload: FrontendConfigs["title"];
}

export const clearConfigLoading = (): ClearConfigLoadingAction => {
  return {
    type: ActionTypes.clearConfigLoading
  };
};

export const setBackgroundImageUrls = (urls: BackgroundImageUrls): SetBackgroundImageUrlAction => {
  return {
    type: ActionTypes.setBackgroundImageUrl,
    payload: urls
  };
};

export const setMobileBackgroundImageUrls = (urls: BackgroundImageUrls): SetMobileBackgroundImageUrlAction => {
  return {
    type: ActionTypes.setMobileBackgroundImageUrl,
    payload: urls
  };
};

export const setPrimaryColor = (color: string): SetPrimaryColorAction => {
  return {
    type: ActionTypes.setPrimaryColor,
    payload: color
  };
};

export const setLogoImageUrls = (urls: LogoImageUrls): SetLogoImageUrlAction => {
  return {
    type: ActionTypes.setLogoImageUrls,
    payload: urls
  };
};

export const setFaviconUrl = (url: URL): SetFaviconUrlAction => {
  return {
    type: ActionTypes.setFaviconUrl,
    payload: url
  };
};

export const setTitle = (title: string): SetTitleAction => {
  return {
    type: ActionTypes.setTitle,
    payload: title
  };
};
