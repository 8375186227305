import axios from "axios";
import i18n from "i18n";
import camelcaseKeys from "camelcase-keys";
import * as Sentry from "@sentry/react";

if (!process.env.REACT_APP_POSTCO_URL) throw new Error("Missing process.env.REACT_APP_POSTCO_URL in customer app");

export const FETCH_ORDER_URL = "/api/v1/orders";
export const POST_RETURNS_URL = "/api/v2/customer/return_orders";
export const GET_REFUND_METHODS_URL = "/api/v2/refund_methods.json";
export const FETCH_POSTCO_LOCATIONS_URL = `${process.env.REACT_APP_POSTCO_URL}/api/retailers`;

interface Options {
  redirect_url: string;
}
export class ConflictError extends Error {
  options: Options;
  constructor(message: string, options: Options) {
    super(message);
    this.name = "ConflictError";
    this.options = options;
  }
}

export const PostCo360API = (shopId?: number | null) => {
  // Set in clients/customer/public/index.html
  const APPLICATION_VERSION = window.APPLICATION_VERSION;

  const client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: { "Application-Version": APPLICATION_VERSION, "shop-id": shopId || "" }
  });

  client.interceptors.request.use(config => {
    config.timeout = 30000;

    if (!config.headers) {
      config.headers = { "Accept-Language": i18n.language };
    } else {
      config.headers["Accept-Language"] = i18n.language;
    }
    return config;
  });

  client.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      const { status: responseStatus, data } = error.response;

      // Data can be undefined
      const errors = data?.errors;
      const redirect_url = data?.redirect_url;

      switch (responseStatus) {
        case 422:
          let errorMessage;

          if (typeof errors === "string") {
            errorMessage = errors;
          } else if (Array.isArray(errors) && errors.every((error: string | Object) => typeof error === "string")) {
            errorMessage = errors.join(", ");
          } else if (typeof errors === "object") {
            errorMessage = Object.entries<string[]>(errors)
              .map(([field, messages]) => {
                return field === "base" ? messages.join(", ") : `${field}: ${messages.join(", ")}`;
              })
              .join(", ");
          } else {
            errorMessage = errors.map((e: { detail: string }) => e.detail).join(", ");
          }
          return Promise.reject(new Error(errorMessage));
        case 404:
          let notFoundErrorMessage;
          if (typeof errors === "string") {
            notFoundErrorMessage = errors;
          } else if (errors.every((error: string | Object) => typeof error === "string")) {
            notFoundErrorMessage = errors.join(", ");
          } else {
            notFoundErrorMessage = "The resource you have specified cannot be found.";
          }
          return Promise.reject(new Error(notFoundErrorMessage));
        case 409:
          return Promise.reject(new ConflictError(errors, { redirect_url }));
        case 427:
          return Promise.reject(
            new Error("There is a new version of the app. Please do a refresh to continue using the app.")
          );
        case 429:
          return Promise.reject(
            new Error(
              "429 Too Many Requests: You have exceeded the rate limit for this service. Please wait a few minutes before trying again."
            )
          );
        default:
          Sentry.captureException(error);
          return Promise.reject(
            new Error(
              "Error processing your request. Please try again in a few minutes. If this problem persists, please contact support."
            )
          );
      }
    }
  );

  return client;
};

export function fetchClient(endpoint: string, { body, ...customConfig }: any = {}) {
  // Set in clients/customer/public/index.html
  const APPLICATION_VERSION = window.APPLICATION_VERSION;

  const headers = {
    "Content-Type": "application/json",
    "Accept-Language": i18n.language,
    "Application-Version": APPLICATION_VERSION
  };

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers
    }
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  return window.fetch(`${process.env.REACT_APP_SERVER_URL}${endpoint}`, config).then(async response => {
    if (response.ok) {
      return camelcaseKeys(await response.json(), { deep: true });
    } else {
      switch (response.status) {
        case 427:
          return Promise.reject(
            new Error("There is a new version of the app. Please do a refresh to continue using the app.")
          );
        default:
          const errorMessage = await response.text();
          return Promise.reject(new Error(errorMessage));
      }
    }
  });
}
