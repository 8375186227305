import { LineItem as ReturnOrderLineItem, ReturnItem } from "actions";

interface BaseLineItem {
  name: string;
  presentmentPrice: PriceWithCurrency;
  price: PriceWithCurrency;
}

export interface ReturningLineItem extends BaseLineItem {
  returnMethodType: string;
  returnMethodName: string;
  bonusCreditAmount: PriceWithCurrency;
}

export interface ExchangingForLineItem extends BaseLineItem {
  imageUrl: string;
  type: string;
}

export interface ExtraLineItem extends BaseLineItem {
  type: string;
  description?: string;
}

export interface LineItem {
  returnItem: ReturningLineItem;
  exchangeItem: ExchangingForLineItem;
}

export interface PriceWithCurrency {
  cents: number;
  currencyIso: string;
}

export interface RefundBreakdown {
  refundMethod: string;
  amount: PriceWithCurrency;
}

export interface OrderSummary {
  lineItem: ReturnOrderLineItem;
  returnItem: ReturnItem;
}

interface BaseInvoice {
  payAt: "after_review" | "after_resolve" | "null";
  presentmentCurrency: string;
  total: PriceWithCurrency;
  type: string;
  subtotal: PriceWithCurrency;
  extraLineItems: ExtraLineItem[];
}

export interface MainInvoice extends BaseInvoice {
  lineItems: LineItem[];
  refundBreakdown: RefundBreakdown[];
  returningLineItems: ReturningLineItem[];
  exchangingForLineItems?: ExchangingForLineItem[];
  totalBeforeChargeAdjustment: PriceWithCurrency;
  chargeAdjustments: PriceWithCurrency;
}

export interface ShipmentInvoice extends BaseInvoice {}

export const nullMainInvoice: MainInvoice = {
  lineItems: [],
  payAt: "after_resolve",
  presentmentCurrency: "USD",
  total: { cents: 0, currencyIso: "USD" },
  type: "",
  extraLineItems: [],
  refundBreakdown: [],
  returningLineItems: [],
  subtotal: { cents: 0, currencyIso: "USD" },
  totalBeforeChargeAdjustment: { cents: 0, currencyIso: "USD" },
  chargeAdjustments: { cents: 0, currencyIso: "USD" }
};

export const nullShipmentInvoice: ShipmentInvoice = {
  payAt: "after_review",
  presentmentCurrency: "USD",
  total: { cents: 0, currencyIso: "USD" },
  type: "",
  subtotal: { cents: 0, currencyIso: "USD" },
  extraLineItems: []
};
