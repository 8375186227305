export interface InstantExchangeData {
  paymentOption: "charge_and_refund" | "save_payment_method";
  shipBackWindow: number | null;
  paymentStatus: string;
}

export const nullInstantExchangeData = {
  paymentOption: "save_payment_method",
  shipBackWindow: null,
  paymentStatus: null
};

export interface InstantExchangeTransactionInfoRow {
  title: string;
  amount: string;
  status: "Charged" | "Charge Failed" | "Refunded" | "Partially Refunded";
  descriptionLines: string[];
}
export interface InstantExchangeTransactionInfo {
  temporaryHold?: InstantExchangeTransactionInfoRow;
  outstandingPayment?: InstantExchangeTransactionInfoRow;
  totalAmountToPay?: InstantExchangeTransactionInfoRow;
  totalOutstandingAmount?: InstantExchangeTransactionInfoRow;
  chargeAdjustment?: InstantExchangeTransactionInfoRow;
}
