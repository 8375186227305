import { useInvoiceContext } from "contexts/InvoiceProvider";
import React from "react";
import { Grid, Icon, Popup } from "semantic-ui-react";
import styles from "../InstantExchangeSection.module.css";
import { useTranslation } from "react-i18next";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { getFormattedPriceString } from "helpers";

interface RowProps {
  title: string;
  amount: string;
  rowType: string;
}

export const ChargeAndRefund = () => {
  const { instantExchangeInvoice, instantExchangeExchangeOrderInvoice: exchangeOrderInvoice } = useInvoiceContext();
  const { t } = useTranslation("orderFlow");
  const { isEmbedded } = useIsEmbedded();
  const { presentmentCurrency: currency } = exchangeOrderInvoice;

  const exchangeOrderTotalCents = exchangeOrderInvoice.total.cents;
  const instantExchangeTotalCents = instantExchangeInvoice.total.cents;
  const exchangeOrderTotalBeforeChargeAdjustmentCents = exchangeOrderInvoice.totalBeforeChargeAdjustment.cents;
  const instantExchangeTotalBeforeChargeAdjustmentCents = instantExchangeInvoice.totalBeforeChargeAdjustment.cents;
  const instantExchangeChargeAdjustmentCents = instantExchangeInvoice.chargeAdjustments.cents;
  const exchangeOrderchargeAdjustmentCents = exchangeOrderInvoice.chargeAdjustments.cents;

  // Amount values when no adjustment
  const exchangeOrderAmount = getFormattedPriceString(exchangeOrderTotalCents, currency);
  const totalDueAmountCents =
    exchangeOrderTotalCents > 0 ? exchangeOrderTotalCents + instantExchangeTotalCents : instantExchangeTotalCents;
  const totalDueAmount = getFormattedPriceString(totalDueAmountCents, currency);

  // Amount valuas when there are adjustment(s)
  const exchangeOrderAmountBeforeChargeAdjustment = getFormattedPriceString(
    exchangeOrderTotalBeforeChargeAdjustmentCents,
    currency
  );
  const totalAmountCentsBeforeChargeAdjustment =
    exchangeOrderTotalBeforeChargeAdjustmentCents > 0
      ? exchangeOrderTotalBeforeChargeAdjustmentCents + instantExchangeTotalBeforeChargeAdjustmentCents
      : instantExchangeTotalBeforeChargeAdjustmentCents;
  const totalAmountBeforeChargeAdjustment = getFormattedPriceString(totalAmountCentsBeforeChargeAdjustment, currency);

  const totalAmountAfterChargeAdjustment = getFormattedPriceString(0, currency);
  const totalChargeAdjustmentCents = instantExchangeChargeAdjustmentCents + exchangeOrderchargeAdjustmentCents;
  const totalChargeAdjustmentAmount = getFormattedPriceString(totalChargeAdjustmentCents, currency, {
    useParenthesis: true
  });

  const InfoRow: React.FC<RowProps> = ({ title, amount, rowType }) => {
    const style = rowType === "totalDue" ? styles.totalDue : styles.subTotalDue;

    return (
      <>
        <Grid.Column computer={12} className={style} style={{ paddingBottom: 0 }}>
          {title}
        </Grid.Column>
        <Grid.Column
          computer={4}
          textAlign="right"
          className={style}
          style={{ paddingLeft: isEmbedded ? "1rem" : 0, paddingBottom: 0 }}
        >
          {amount}
        </Grid.Column>
      </>
    );
  };

  let tooltipContent: string;

  if (Boolean(instantExchangeChargeAdjustmentCents) && Boolean(exchangeOrderchargeAdjustmentCents)) {
    tooltipContent = "The exchange order outstanding amount and instant exchange temporary amount hold are waived.";
  } else {
    tooltipContent = "The instant exchange temporary hold amount is waived.";
  }

  const ChargeAdjustmentRow = () => (
    <>
      <Grid.Column computer={12} className={styles.chargeAdjustment}>
        {t("summary.chargeAdjustment")}
        <Popup
          trigger={<Icon name="info circle" size="small" />}
          content={tooltipContent}
          position="top center"
          wide="very"
        />
      </Grid.Column>
      <Grid.Column
        computer={4}
        textAlign="right"
        className={styles.chargeAdjustment}
        style={{ paddingLeft: isEmbedded ? "1rem" : 0, paddingRight: "1rem" }}
      >
        {totalChargeAdjustmentAmount}
      </Grid.Column>
    </>
  );

  const InstantExchangeOutstanding = () => (
    <>
      {exchangeOrderTotalCents > 0 && (
        <InfoRow
          title={t("summary.instantExchange.outstandingPayment")}
          amount={exchangeOrderAmount}
          rowType="subTotalDue"
        />
      )}
      <InfoRow title={t("summary.instantExchange.totalDue")} amount={totalDueAmount} rowType="totalDue" />
    </>
  );

  const InstantExchangeOutstandingWithAdjustment = () => (
    <>
      {exchangeOrderTotalBeforeChargeAdjustmentCents > 0 && (
        <InfoRow
          title={t("summary.instantExchange.outstandingPayment")}
          amount={exchangeOrderAmountBeforeChargeAdjustment}
          rowType="subTotalDue"
        />
      )}

      <InfoRow
        title={t("summary.instantExchange.totalDue")}
        amount={totalAmountBeforeChargeAdjustment}
        rowType="totalDue"
      />
      <ChargeAdjustmentRow />
      <InfoRow
        title={t("summary.instantExchange.totalAfterChargeAdjustment")}
        amount={totalAmountAfterChargeAdjustment}
        rowType="totalDue"
      />
    </>
  );

  if (exchangeOrderchargeAdjustmentCents !== 0 || instantExchangeChargeAdjustmentCents !== 0) {
    return <InstantExchangeOutstandingWithAdjustment />;
  } else {
    return <InstantExchangeOutstanding />;
  }
};
